import React, { Component, FormEvent } from 'react';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles';

import {
  Avatar,
  Grid,
  FormControlLabel,
  FormControl,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Grow,
  IconButton,
  Switch,
  ButtonBase,
  Select,
  MenuItem
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import Input from '../components/Input';
import "../styles/home.css";

import { observer, inject } from 'mobx-react';
import QNRTC from "qnweb-rtc";
import { UserStore } from '../stores/userStore';
import { RoomStore, TrackCreateMode } from '../stores/roomStore';
import { RouterStore } from 'mobx-react-router';
import { verifyRoomId, verifyUserId, getColorFromUserId } from '../common/utils';
import { MessageStore } from '../stores/messageStore';
import { Link } from 'react-router-dom';
import niu from '../assets/niu.svg';
import qiniu from '../assets/ykj.png';


const styles = (theme: Theme) => createStyles({
  avatar: {
    margin: 25,
    width: 200,
    height: 200,
    fontSize: 32,
  },
  button: {
    margin: theme.spacing.unit,
    width: 500,
    height: 55,
  },
  root: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: '100vh',
    padding: `${theme.spacing.unit}px`,
  },
  wrapper: {
    maxWidth: 400,
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  formLabel: {
    textAlign: 'center',
  },
  radioGroup: {
    justifyContent: 'center',
    width: 500,
  },
  linkWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '30px 20px 0'
  },
  linkRight: {
    color: 'rgba(255, 255, 255, 0.5)',
    fontSize: '10px',
    lineHeight: '16px',
    bottom: '16px',
    right: '16px',
  },
  linkLeft: {
    color: 'rgba(255, 255, 255, 0.5)',
    fontSize: '10px',
    lineHeight: '16px',
    bottom: '16px',
    left: '16px',
    '& > p': {
      margin: "1px 0 0 0"
    }
  },
});

interface Props extends WithStyles<typeof styles> {
  routerStore: RouterStore;
  userStore: UserStore;
  roomStore: RoomStore;
  messageStore: MessageStore;
  isMobile: Boolean,
}

interface State {
  userid: string;
  roomid: string;
  roomToken: string;
  // enhance: boolean;
  // selected: number;
  joinRoomStep: number;
  inited: boolean,
  showScreenConfig: boolean;
}

@inject('routerStore', 'userStore', 'roomStore', 'messageStore', 'isMobile')
@observer
class Home extends Component<Props, State> {


  private fileinput: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

  state: State;

  constructor(props: Props) {
    super(props);
    this.state = {
      userid: props.userStore.id,
      roomid: props.roomStore.id,
      roomToken: '',
      joinRoomStep: 0,
      inited: true,
      showScreenConfig: this.isShowScreenConfig(props.roomStore.selectedTrackCreateMode),
    };
  }


  private isShowScreenConfig(mode: TrackCreateMode) {
    return mode === TrackCreateMode.C || mode === TrackCreateMode.D;
  }

  handleRadioChange = async (event: React.ChangeEvent<{}>, value: string) => {
    const mode = value as TrackCreateMode;
    const showScreenConfig = this.isShowScreenConfig(mode);
    this.props.roomStore.setSelectedTrackCreateMode(mode);
    this.setState({ showScreenConfig });
  };

  onChangeScreenConfig = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.roomStore.setScreenConfig(event.target.value as "480p" | "720p" | "1080p");
  }

  showMessage = this.props.messageStore.show;

  verifyState = (): boolean => {
    const { userid, roomid } = this.state;
    const isValidUserid = verifyUserId(userid);
    const isValidRoomid = verifyRoomId(roomid);
    if (!isValidUserid && isValidRoomid) {
      this.showMessage('用户名限制 3~50 个字符，并且只能包含字母、数字或下划线');
      return false;
    }
    if (!isValidRoomid && isValidUserid) {
      this.showMessage('房间名限制 3~64 个字符，并且只能包含字母、数字或下划线');
      return false;
    }
    if (!isValidRoomid && !isValidUserid) {
      this.showMessage('用户名 3~50 个字符、房间名 3~64 个字符，并且只能包含字母、数字或下划线');
      return false;
    }
    return true;
  }

  handleNext(type: string, e: FormEvent) {
    const { userid, roomid, roomToken } = this.state;

    const isValidUserid = verifyUserId(userid);
    if (!isValidUserid) {
      this.showMessage('考场ID应为3~50 个字符');
      return;
    }
    this.props.userStore.setId(userid);
    this._checkTheKaochang()
    return;

  };

  handleLiveClick = () => {
    const { userid, roomid } = this.state;
    if (this.verifyState()) {
      this.props.userStore.setIdNoStore(userid);
      this.props.roomStore.setId(roomid);
    }
  };

  componentDidMount() {
    document.title = "中国音协考级广东考区";
    if (this.state.joinRoomStep == 1) {
      //检测考场状况
      this.props.userStore.checkKaochangInfo().then(ret => {
        // 如果曾经输入过考场，那么我们跳转过去
        if (ret['code'] == 0) {
          const { userid } = this.state;

          this.setState({ inited: true })
          this.props.routerStore.push(`/exam_room/${userid}`);

        }
      })
    }
  }

  _checkTheKaochang() {
    this.props.userStore.checkKaochangInfo().then(ret => {
      // 如果曾经输入过考场，那么我们跳转过去
      if (ret['code'] == 0) {
        const { userid } = this.state;
        this.props.routerStore.push(`/exam_room/${userid}`);
      }
    }).catch(err => { })
  }


  render() {
    const options = [
      {
        label: "480p(640x480 15fps 500kbps)",
        value: "480p"
      },
      {
        label: "720p(1280x720 30fps 1130kbps)",
        value: "720p"
      },
      {
        label: "1080p(1920x1080 30fps 3000kbps)",
        value: "1080p"
      }
    ];

    const { classes, isMobile } = this.props;
    const { showScreenConfig, inited } = this.state;
    // const { enhance } = this.state;
    if (!inited) {
      return null;
    }
    return (
      <div className={classes.root}>
        <IconButton
          style={{
            pointerEvents: this.state.joinRoomStep !== 0 ? "auto" : "none",
            opacity: this.state.joinRoomStep !== 0 ? 1 : 0,
            zIndex: 10,
            top: '16px',
            right: '16px',
            position: 'absolute',
          }}
          onClick={() => this.props.routerStore.push('/settings')}
        >
          <SettingsIcon color="inherit" />
        </IconButton>
        <Grid
          container
          direction="column"
          justify="center"
          spacing={16}
        >
          {/* 头像与考场ID start */}
          <Grid item container wrap="nowrap" justify="center" spacing={16}>
            <Grid item>
              <Avatar className={classes.avatar} src={qiniu}></Avatar>

              <p className="home_user">中国音协广东考区</p>
            </Grid>
          </Grid>
          {/* 头像与考场ID end */}
          {this.state.joinRoomStep === 0 && <Grid item container wrap="nowrap" justify="center" spacing={16}>
            <Grid item>
              <FormControl className={classes.formControl} aria-describedby="userid-text">
                <Input
                  placeholder="请输入考场ID"
                  value={this.state.userid}
                  onChange={(e) => this.setState({ userid: e.target.value })}
                />
              </FormControl>
            </Grid>
          </Grid>}

          {this.state.joinRoomStep === 1 && <Grid item container wrap="nowrap" justify="center" spacing={16}>
            <Grid item>
              <FormControl className={classes.formControl}>
                <RadioGroup
                  className={`${classes.radioGroup} radioGroup`}
                  name="mode"
                  row
                  value={this.props.roomStore.selectedTrackCreateMode}
                  onChange={this.handleRadioChange}
                >
                  <FormControlLabel value={TrackCreateMode.A} control={<Radio />} label={TrackCreateMode.A} />
                  <FormControlLabel value={TrackCreateMode.B} control={<Radio />} label={TrackCreateMode.B} />
                  {!isMobile && <FormControlLabel value={TrackCreateMode.C} control={<Radio />} label={TrackCreateMode.C} />}
                  {!isMobile && <FormControlLabel value={TrackCreateMode.D} control={<Radio />} label={TrackCreateMode.D} />}
                </RadioGroup>
                {showScreenConfig && <Select value={this.props.roomStore.screenConfig} onChange={this.onChangeScreenConfig}>
                  {options.map(opt => <MenuItem value={opt.value} key={opt.value}>{opt.label}</MenuItem>)}
                </Select>}
              </FormControl>

            </Grid>
          </Grid>}
          <Grid item container wrap="nowrap" justify="center" spacing={16}>
            <Grid item>
              <ButtonBase
                focusRipple
                onClick={this.handleNext.bind(this, 'room')}
                className="home_btn"
              >
                进入考场
              </ButtonBase>
            </Grid>
          </Grid>

        </Grid>
        {/* <img className="niu" src={niu} /> */}
        <input
          ref={this.fileinput}
          type="file"
          style={{ display: 'none' }}
          accept=".mp3, .ogg"
        />
      </div>
    );
  }
}

export default withStyles(styles)(Home);
