import React, { Component, FormEvent } from 'react';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles';

import {
  Avatar,
  Grid,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Grow,
  IconButton,
  Switch,
  ButtonBase,
  Select,
  MenuItem
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import Input from '../components/Input';
import "../styles/home.css";

import { observer, inject } from 'mobx-react';
import QNRTC from "qnweb-rtc";
import { UserStore } from '../stores/userStore';
import { RoomStore, TrackCreateMode } from '../stores/roomStore';
import { RouterStore } from 'mobx-react-router';
import { verifyRoomId, verifyUserId, getColorFromUserId } from '../common/utils';
import { MessageStore } from '../stores/messageStore';
import { Link } from 'react-router-dom';
import niu from '../assets/niu.svg';
import qiniu from '../assets/ykj.png';


const styles = (theme: Theme) => createStyles({
  avatar: {
    margin: 14,
    width: 200,
    height: 200,
    fontSize: 32,
    objectFit: 'cover'
  },
  button: {
    margin: theme.spacing.unit,
    width: 500,
    height: 55,
  },
  root: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: '100vh',
    padding: `${theme.spacing.unit}px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white'

  },
  wrapper: {
    maxWidth: 400,
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  formLabel: {
    textAlign: 'center',
  },
  radioGroup: {
    justifyContent: 'center',
    width: 500,
  },
  linkWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '30px 20px 0'
  },
  linkRight: {
    color: 'rgba(255, 255, 255, 0.5)',
    fontSize: '10px',
    lineHeight: '16px',
    bottom: '16px',
    right: '16px',
  },
  linkLeft: {
    color: 'rgba(255, 255, 255, 0.5)',
    fontSize: '10px',
    lineHeight: '16px',
    bottom: '16px',
    left: '16px',
    '& > p': {
      margin: "1px 0 0 0"
    }
  },
  header: {
    padding: `${theme.spacing.unit * 3}px`,
    height: '80px',
    overflow: 'visible',
  },
  headerContent: {
    height: `${80 - (theme.spacing.unit * 3)}px`,
    lineHeight: `${80 - (theme.spacing.unit * 3)}px`,
    overflow: 'visible',
  },

  alertTitle: {
    color: "#3d3d3d",
    fontSize: 26,
    fontWeight: "bold"
  }
});

interface Props extends WithStyles<typeof styles> {
  routerStore: RouterStore;
  userStore: UserStore;
  roomStore: RoomStore;
  messageStore: MessageStore;
  isMobile: Boolean,
}

interface State {
  userid: string;
  roomid: string;
  roomToken: string;
  // enhance: boolean;
  // selected: number;
  joinRoomStep: number;
  inited: boolean,
  lastMd5: string;
  lastData: any
}

@inject('routerStore', 'userStore', 'roomStore', 'messageStore', 'isMobile')
@observer
class Home extends Component<Props, State> {


  private fileinput: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

  state: State;
  // _localTimer;

  _localTimer: NodeJS.Timer | null = null
  mounted: boolean | boolean = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      userid: props.userStore.id,
      roomid: props.roomStore.id,
      roomToken: '',
      lastMd5: '',
      joinRoomStep: props.userStore.id ? 1 : 0,
      inited: false,
      lastData: {}
    };
  }


  showMessage = this.props.messageStore.show;
  userStore = this.props.userStore;


  componentDidMount() {
    this.mounted = true;
    this._fetchKaochangWorkingExam();
  }

  componentWillUnmount() {
    this.mounted = false;
    clearInterval(Number(this._localTimer))
  }

  /**
   * 获取考场情况
   */
  _fetchKaochangWorkingExam() {
    //检测考场状况
    const that = this;
    clearInterval(Number(this._localTimer))
    that.userStore.getWorkingExam().then(ret => {
      if (!that.mounted) {
        return;
      }
      if (ret['code'] == 0) {
        document.title = ret['data']['pageTitle'] || "中国音协考级广东考区";
        if (ret['data']['unikey'] != that.state.lastMd5) {
          that.setState({ inited: true, lastMd5: ret['data']['unikey'], lastData: ret['data'] })
          if (ret['data']['exam'] != null) {
            if (ret['data']['exam']['status'] == 1) {
              //正在考试，那么我们要跳转过去了
              clearInterval(Number(this._localTimer))
              this.props.routerStore.push(`/room/${ret['data']['exam']['linkId']}`);
              return
            }
          }
        }
        if (ret['data']['checkConfig']) {
          that.refetchWorkingExam(ret['data']['checkConfig']['seconds'] * 1000)
        }
      }
    }).catch(err => { })
  }

  refetchWorkingExam(nextSeconds: number) {
    clearInterval(Number(this._localTimer))
    this._localTimer = setInterval(() => {
      this._fetchKaochangWorkingExam()
    }, nextSeconds);
  }

  render() {
    const { classes, isMobile } = this.props;
    const { inited, lastData } = this.state;
    // const { enhance } = this.state;
    if (!inited) {
      return null;
    }

    //没数据时的显示
    if (lastData['empty'] != null) {
      return (
        <div className={classes.root}>
          <span className={classes.headerContent} style={lastData['empty']['style']}>{lastData['empty']['txt']}</span>
          {/* <span style={lastData['empty']['style']}>{lastData['empty']['txt']}</span> */}
        </div>
      )
    }


    return (<div className={classes.root}>
      <img className={classes.avatar} src={lastData['exam']['examInfo']['avatarPath2']['src']} style={{ 'height': lastData['exam']['examInfo']['avatarPath2']['height'], 'width': lastData['exam']['examInfo']['avatarPath2']['width'] }}></img>


      {
        lastData['exam']['examInfo']['desc']['list'].map((v: any, i: number) => {
          // 调用子组件 循环的也是子组件
          return <p key={i} className={classes.alertTitle} style={{ 'color': v['color'], 'fontSize': v['size'] }}>{v['txt']}</p>
        }
        )
      }
    </div>)

  }
}

export default withStyles(styles)(Home);
