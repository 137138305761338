import { observable, action, computed } from 'mobx';
import store from 'store';
import { checkKaochang, checkKaochangLinkingExam } from '../common/api';

export class UserStore {

  /** session.userID */
  @observable
  public id: string = store.get('userid') || '';
  // public id: string =  '';

  @computed
  public get isAdmin() {
    return this.id === 'admin';
  }

  @action
  public setId(userID: string) {
    this.id = userID;
    store.set('userid', userID)
  }

  @action
  public setIdNoStore(userID: string) {
    this.id = userID;
  }

  @action
  public async checkKaochangInfo(): Promise<any> {
    if (!this.id) return '';

    const data = await checkKaochang( this.id);
    return data;
  }

  @action
  public async getWorkingExam(): Promise<any> {
    //获取考场当前信息
    if (!this.id) return '';

    const data = await checkKaochangLinkingExam( this.id);
    return data;
  }
}

export default new UserStore();
